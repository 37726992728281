var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "picture-capture" }, [
    _c("div", { staticClass: "picture-capture__inner" }, [
      _c(
        "div",
        { staticClass: "picture-capture__media-container" },
        [
          !_vm.streamReady
            ? _c(
                "div",
                { staticClass: "picture-capture__loader" },
                [
                  _c("v-progress-circular", {
                    staticStyle: { color: "#fff" },
                    attrs: { indeterminate: "" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.localTrack && _vm.videoVisible
            ? _c("UserVideo", {
                ref: "video",
                staticClass: "picture-capture__media",
                attrs: { track: _vm.localTrack },
                on: {
                  ready: function ($event) {
                    _vm.streamReady = true
                  },
                },
              })
            : _vm._e(),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.videoVisible,
                expression: "!videoVisible",
              },
            ],
            staticClass: "picture-capture__media",
            attrs: { src: _vm.imageSrc },
          }),
          _vm.countdownVisible
            ? _c("span", { staticClass: "picture-capture__countdown" }, [
                _vm._v(" " + _vm._s(_vm.countdown) + " "),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "picture-capture__controls" },
        [
          _vm.triggerButtonVisible
            ? [
                _c(
                  "RtbButton",
                  {
                    attrs: {
                      variant: "icon",
                      color: "danger",
                      size: "lg",
                      circle: "",
                      title: "Take a photo",
                      id: _vm._uid + "-take-photo-button",
                    },
                    on: { click: _vm.takePictureHandler },
                  },
                  [
                    _c("svg-icon", {
                      staticStyle: { position: "relative", top: "-1px" },
                      attrs: { name: "camera-solid" },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      target: _vm._uid + "-take-photo-button",
                      placement: "right",
                    },
                  },
                  [_vm._v("Take a photo")]
                ),
              ]
            : _vm._e(),
          _vm.imageSrc !== "" && _vm.submitted === false
            ? [
                _c(
                  "RtbButton",
                  {
                    attrs: {
                      variant: "icon",
                      color: "success",
                      size: "lg",
                      circle: "",
                      title: "Submit",
                      id: _vm._uid + "-submit-button",
                    },
                    on: { click: _vm.submit },
                  },
                  [_c("svg-icon", { attrs: { name: "check-regular" } })],
                  1
                ),
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      target: _vm._uid + "-submit-button",
                      placement: "right",
                    },
                  },
                  [_vm._v("Submit")]
                ),
                _c(
                  "RtbButton",
                  {
                    staticClass: "relative",
                    attrs: {
                      variant: "icon",
                      color: "transparent-dark",
                      size: "lg",
                      circle: "",
                      title: "Reset",
                      animated: "",
                      id: _vm._uid + "-reset-button",
                    },
                    on: { click: _vm.reset },
                  },
                  [
                    _c("svg-icon", { attrs: { name: "undo-regular" } }),
                    _c(
                      "span",
                      {
                        staticClass: "absolute mt-2",
                        staticStyle: {
                          left: "50%",
                          top: "100%",
                          transform: "translate(-50%, -100%)",
                          "font-size": "10px",
                        },
                      },
                      [_vm._v("Reset")]
                    ),
                  ],
                  1
                ),
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      target: _vm._uid + "-reset-button",
                      placement: "right",
                    },
                  },
                  [_vm._v("Reset")]
                ),
              ]
            : _vm._e(),
          _vm.submitted && _vm.isMobile
            ? _c(
                "RtbButton",
                {
                  staticClass: "font-bold capitalize text-lg px-3",
                  staticStyle: { "min-width": "180px" },
                  on: { click: _vm.takeNextPhoto },
                },
                [_vm._v(" Take next Photo ")]
              )
            : _vm._e(),
        ],
        2
      ),
      _c("canvas", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        ref: "canvas",
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }