




































































































import Vue from "vue"
import { mapGetters } from "vuex"
import { BTooltip } from "bootstrap-vue"
import type { WithRefs } from "vue-typed-refs"
import User from "@shared/User"

import { RtbButton } from "@/components/ui"

import { TimeoutMixinFactory } from "../mixins/timeout"

import UserVideo from "@/components/GroupTeams/Common/User/UserVideo.vue"

export type Refs = {
  canvas: HTMLCanvasElement
}

export default (Vue as WithRefs<Refs>).extend({
  name: "PictureCapture",
  mixins: [TimeoutMixinFactory()],
  components: {
    BTooltip,
    RtbButton,
    UserVideo
  },
  props: {
    customVideoUserID: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      file: null,
      imageSrc: "",
      takingPicture: false,
      submitted: false,
      streamReady: false
    }
  },
  computed: {
    ...mapGetters("twilio", { twilioUsers: "users" }),
    localTrack() {
      return this.twilioUsers[this.customVideoUserID ?? this.userID]?.videoTrack
    },
    userID() {
      return this.user.id
    },
    user() {
      return this.$store.getters.user
    },
    isMobile() {
      return User.isMobile(this.user)
    },
    countdownVisible() {
      return this.intervalID !== null
    },
    videoVisible() {
      return this.imageSrc === ""
    },
    triggerButtonVisible() {
      return this.imageSrc === "" && this.takingPicture === false
    }
  },
  methods: {
    takePictureHandler() {
      this.takingPicture = true
      this.runTimeout(this.onTakePicture)
    },
    async onTakePicture() {
      const canvas = this.$refs.canvas
      const context = canvas.getContext("2d")

      const { dimensions } = this.localTrack
      const { width, height } = dimensions

      canvas.width = width
      canvas.height = height

      context.translate(width, 0)
      context.scale(-1, 1)
      context.drawImage(this.$refs.video.$el, 0, 0, width, height)

      const canvasToJpg = canvas =>
        new Promise(resolve =>
          canvas.toBlob(blob => resolve(blob), "image/jpeg", 0.8)
        )

      this.file = await canvasToJpg(canvas)
      this.imageSrc = URL.createObjectURL(this.file)

      this.takingPicture = false
    },
    submit() {
      this.$emit("sendFile", this.file)
      this.submitted = true
    },
    reset() {
      this.imageSrc = ""
      this.file = null
    },
    takeNextPhoto() {
      this.reset()
      this.submitted = false
    }
  }
})
